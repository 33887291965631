/*add link to ./css/loader.css */

 @import url("./css/loader.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 

[data-show="false"] {
  display: none !important;
}

.tools-container {
  display: flex;
width: 100%;
  margin-top: 20px;
  flex-flow: column;
  border: 1px dashed #888;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
}
#merchantsList {
  width: 100%;
  display: flex;
  font-size: 12px;
}
.enc-container {
  display: flex; 
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
}
.tools-inner-container {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.tools-radio-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

 #clockinfo {
    font-size: 15px;
    margin-left: 4px;
}

.polLabel {
  border:1px solid #888;
  background-color: #282c34;
  border-radius: 5px;
  padding: 10px;

}
 
.polLabel .plamount {
  color:white;
  font-weight: bold;
}
.polLabel .pltitle {
  color:white;
}