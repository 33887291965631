body {
    padding: 20px;
    overflow: hidden;
    font-family: "Montserrat", sans-serif;
    xborder: 1px dashed orange;
}

.merchant {
 
    align-items: center;
    margin-bottom: 20px;
}
.merchants_table {
    border-collapse: collapse;
    width: 100%;
 
}
.products_no_data {
            width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #717171;
    font-size: 16px;
}
.bar_chart_no_data {
        width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #717171;
    font-size: 16px;
}
.line_chart_legend {
    display: flex;
    gap: 10px;
    align-items: center;    
    font-size: 14px;
}
.legend_dashed {
    height: 1px;
    border-bottom:2px dashed #FC8E6C;
    
    width: 30px;
}
.legend_line {
    height: 1px;
    border-bottom:2px solid #F15A2B;
    
    width: 30px;
}
 .bar_chart_row_label {
    padding-left: 10px;
  padding-left: 6px;
    font-size: 15px;
    margin-bottom: -5px;
    font-size: 15px;
 }
.merchants_table td {
    border:1px solid black;
    padding: 10px;
}
.liveViewContainer {
    min-height: 500px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.products_container {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 25%;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
}
.products_container_internal {
        height: fit-content;
    display: flex;
    flex-flow: column;
}
.product_quantity {
    display: flex;
    text-wrap: nowrap;
}
.cont_encrypt, .cont_decrypt {
    margin-top: 10px;
    border: 1px solid black;
    padding: 20px;
    display: inline-block;
    min-height: 120px;
    vertical-align: top;
    margin-right: 10px;
}
.decenc_button {
    float: right;
    margin-top: 20px;
}
.globeControler_container {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 200;
    cursor: pointer;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

 .lv_header_sub {
    display: flex;
    height: 24px;
    fill:#F15A2B;
 }
 .lv_header_sub svg {
    fill:#F15A2B;
 }
.lv_header {
    font-size: 26px; 
    margin-right: 9px;  
}
.header_left_title {
    display: flex;
    align-items: center;
}
.lv_header_left {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    flex-flow: column;
    align-items: start;
    gap: 7px;    
}
.header_left_bottomrow {
    display: flex;
    gap: 3px;
}
.info_icon {
    display: flex;
    align-items: center;
    margin-left: 4px;
}
.info_icon span {
    display: flex;
}
.lv_header_clock {
    color:#7C7C7C;
    fill: #7c7c7c;
    margin-right: 3px;
    display: flex;
    align-items: center;
   
}
.lv_header_clock span {
    display: flex;
}
.lv_header_clock i {
    font-size: 19px;
}
.lv_header_time{
    color: #7C7C7C;
    font-size: 15px;

}
.lv_header_time svg {
    fill:#7C7C7C;
}
.live_view_dashboard_container {
    display: flex;
    width: 100%;
    gap: 20px;
    min-height: 580px;
}
.dashboard_root {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 100%;
}
.dashboard_bars_container {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-flow: wrap;
}
.bar_chart_container {
    display: flex;
    flex-flow: column;
    gap: 20px; 
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    box-sizing: border-box;
    flex: 1;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
}
.bar_chart_inner_container {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 20px;
 
}
.bar_chart_rows {
    display: flex;
    flex-flow: column;
    gap: 5px; 
    width: 100%;
}
.bar_chart_title {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
 
}
.products_list {
    display: flex;
    flex-flow: column;    
    width: 100%;
    margin-top: 20px;
    height: 100%;
    justify-content: space-around;    
    gap:15px;
}
.product_name {
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 60px;   
}
.product_name i {
    margin-right: 8px;
}
.db_linechart_inner_container {
    display: flex;
    flex-flow: column;
    gap: 20px;
    margin: 20px;
}
.line_chart_title_row {
    display: flex;
    justify-content: space-between;
}
.product_item {
    display: flex;
    justify-content: space-between;    
    font-size: 15px;
    }
.bar_chart_row {
   height:15px;
   background-color: #F15A2B;
   border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.bar_value {
    margin-left: 20px;
    font-size: 15px;
}
.bar_chart_row_value_container {
    display: flex;
    padding: 5px;
    align-items: center;
}
.dashboard_linecharts_container {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-flow: column;
}

.db_linechart_box {
    display: flex;
    flex-flow: column;
    gap: 20px;
    min-height: 300px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    box-sizing: border-box;
    flex: 1;
    border-radius: 10px;
    background-color: #fff;
}
.switch_container {
    display: inline;
}
.dbic_title {
    display: flex;
    align-items: center;
    color: #717171;
    fill: #717171;
    font-size: 16px;
}
.dbic_title svg {
    margin-right: 8px;
}
.lv_dash_left {
    width: 25%;
    min-width: 25%;
    min-height: 200px;
    display: flex;
    flex-flow: column;
    gap: 25px;
    justify-content: space-between;
}
.title_icon {
    margin-right: 8px;
}
.lv_dash_right {
    flex-grow: 1;
    min-height: 200px;
    flex: 1; /* Takes the remaining width */
    display: flex; /* Optional, to ensure the globe can be centered or controlled */
    justify-content: center; /* Optional, centers the globe horizontally */
    align-items: center; /* Optional, centers the globe vertically */    
}
.tropy_icon {
    margin-right: 4px;
  
}
.tropy_icon path {
    xstroke: white;
}
.box_tooltip {
    position: absolute;
    top: 15px;
    right: 15px;
    color:#7C7C7C;
}
.box_icon {
    width: 24px;
    height: 24px;
    color:#717171;
}
.lv_data_box {
    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
   background-color: #fff;
   box-shadow: 0 0 10px rgba(0,0,0,0.1);
     flex-grow: 1;
   width: 100%;
   border-radius: 10px; 
   position: relative;

}
.line_chart_title {
    font-size: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap:5px;
    fill: #717171;
}
.last_order_container {
    position: absolute;
    bottom: 40px;
    left: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    min-width: 280px;
    min-height: 35px;
    border-radius: 10px;
    font-size: 14px;
    align-items: center;
    padding: 10px 20px 10px 20px;
    display: flex;
    z-index: 200;
    background-color: #fff;
    max-width: 280px;
    flex-flow: column;
    box-sizing: border-box;
    line-height: 24px;
}
.currencySwitchContainer {
    display: inline-flex; 
    flex-flow: wrap;
    margin-left:30px;
    height: 30px;
    border-radius: 15px;
    width: 150px;
    justify-content: space-between;
    font-size: 14px;
}
.currencySwitchContainer div{
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #B0B0B0;
}
.currencySwitchContainer .left_cur{
    background-color: #f0f0f0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;    
}

.currencySwitchContainer .right_cur{
    background-color: #f0f0f0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.currencySwitchContainer [data-selected='true'] {
    background-color: #FDF2EE !important;
    border:1px solid #E96F4B !important;
    font-weight: bold;
}
.globe_no_data {
    position: absolute;
    width: 200px;
    left: 50%;
    margin-left: -100px;
    text-align: center;
    height: 200px;
    top: 50%;
    margin-top: -100px;
    background-color: #fff;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#717171;
    font-size: 16px;
  }
  .beta_box{
    font-size:12px;
    font-weight: 600;
    margin-left: 6px;
    background-color: #3B3E41;
    color:white;
    padding: 3px;
    border-radius: 4px;
  }

   .live_view_globe_container {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 100%;
    position: relative;
    height: auto;
    min-height: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 10px;
    align-items: center;
    background-color: #fff;
   }
.live_view_globe_container:hover {
xpointer-events: auto !important;

}
   .lv_data_box_inner_container {
    margin: 16px 25px 25px 16px;
    display: flex;
    gap: 10px;
    flex-flow: column;

   }

   .dbic_value {
    font-size: 37px;
    font-weight: 600;
   }

   .lv_dash_right > * {
  width: 100%;
  height: 100%;
}

.ttEditBox {
    display: flex;
    flex-flow: column;
    gap: 10px;
    margin-bottom: 30px;
}
.ttEditBox input, .ttEditBox textarea {
    font-size: 24px;
    padding: 4px;
}
.configEditor_outer {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 100%;
    align-items: center;
}
.configEditor_inner {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 900px;
}
.save_container {
    display: flex;
    justify-content: start;
    gap: 20px;    
}
.save_container button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #F15A2B;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.config_auth_container {
    display: flex;
    gap:20px;
}
.config_auth_container input {
    font-size: 16px;
    padding: 4px;
}